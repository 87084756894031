










import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OAddAlert: () =>
      import(
        "@/components/organisms/event/modules/alertModule/o-add-alert.vue"
      ),
  },

  setup() {
    return {};
  },
});
